import { Component, OnInit } from '@angular/core';
import { SmartAssistantService } from "../../../services/smart-assistant.service";
import { AssistantInfo } from "../../../dto/assistant/assistant-info.dto";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SaveAssistantRequest } from "../../../dto/assistant/save-assistant-request.dto";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-file-search-assistant',
  templateUrl: './file-search-assistant.component.html',
  styleUrls: ['./file-search-assistant.component.css']
})
export class FileSearchAssistantComponent implements OnInit {

  public assistantForm: FormGroup;
  public assistant: AssistantInfo;

  constructor(
    private readonly smartAssistantService: SmartAssistantService,
    private readonly toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.initializeForm();
    this.loadFileSearchAssistant();
  }

  private initializeForm(): void {
    this.assistantForm = new FormGroup({
      instructions: new FormControl('', [Validators.required]),
    });
  }

  private loadFileSearchAssistant() {
    this.smartAssistantService.getFileSearchAssistant().subscribe({
      next: (assistant: AssistantInfo) => {
        this.assistant = assistant;
        if (!this.assistant) this.assistant = new AssistantInfo();
        this.assistantForm.get('instructions').setValue(this.assistant.instructions);
      }
    });
  }

  public saveFileSearchAssistant() {

    if (this.assistantForm.invalid) {
      this.assistantForm.markAllAsTouched();
      return;
    }

    const saveAssistantRequest: SaveAssistantRequest = {
      instructions: this.assistantForm.value.instructions
    };

    this.smartAssistantService.saveFileSearchAssistant(saveAssistantRequest).subscribe({
      next: (assistant: AssistantInfo) => {
        this.assistant = assistant;
        this.toastr.success('Asistente de búsqueda de archivos guardado exitosamente.');
      },
      error: (error: any) => {
        console.error('Error al guardar el asistente de búsqueda de archivos:', error);
        this.toastr.error('Error al guardar el asistente de búsqueda de archivos.');
      }
    });
  }
}
