import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GetSignedUrlRequest } from "../dto/file/get-signed-url-request.dto";
import { GetSignedUrlResponse } from "../dto/file/get-signed-url-response.dto";

@Injectable({
  providedIn: 'root',
})
export class FileService {

  private apiUrl: string = `${environment.apiUrl}/files`;

  constructor(
    private http: HttpClient,
  ) {

  }

  public uploadMedia(signedUrl: string, file: File) {

    const headers = new HttpHeaders({
      'Content-Type': file.type,
    });

    return this.http.put<any>(signedUrl, file, {
      headers: headers
    });
  }

  public getSignedFileUrl(getSignedUrlRequest: GetSignedUrlRequest): Observable<GetSignedUrlResponse> {
    const endpoint = `${this.apiUrl}/get-signed-url`;
    return this.http.post<GetSignedUrlResponse>(endpoint, getSignedUrlRequest);
  }

  public readTxtFile(fileUrl: string) {
    return this.http.get(fileUrl, { responseType: 'text' });
  }

}
