import { BotMessageResponse } from "./bot-message-response.dto";

export class Message {

  public message: string;
  public botMessage: BotMessageResponse;
  public bot: boolean;

  constructor(message: string, botMessage: BotMessageResponse, bot: boolean) {
    this.message = message;
    this.bot = bot;
    this.botMessage = botMessage;
  }

}
