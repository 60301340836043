<h2 class="mb-2">
  Buscador de archivos
</h2>
<p class="text-700 lh-sm mb-2">
  Administrador para el asistente de búsqueda de archivos.
</p>
<div class="col-6">
  <form [formGroup]="assistantForm" (ngSubmit)="saveFileSearchAssistant()">
    <div class="mb-3">
      <label class="form-label" for="instructions">Instrucciones</label>
      <textarea
        class="form-control scrollbar"
        id="instructions"
        placeholder="Escribe las instrucciones del asistente de búsqueda de archivos"
        formControlName="instructions"
        style="height: 400px"
        [ngClass]="{'is-invalid': assistantForm.get('instructions').touched && assistantForm.get('instructions').invalid}"
      ></textarea>
      <div *ngIf="assistantForm.get('instructions').touched && assistantForm.get('instructions').invalid"
           class="invalid-feedback">
        Las instrucciones son requeridas.
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button type="submit" class="btn btn-primary" [disabled]="assistantForm.invalid">Guardar</button>
    </div>
  </form>
</div>
