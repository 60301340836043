
export class StartChatRequest {

  threadId: string;
  textMessage: string;
  requiresAudioResponse: boolean;
  audioFileKey: string;

  constructor() {
    this.requiresAudioResponse = true;
  }

}
