import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { LidiaComponent } from "./components/lidia/lidia.component";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { layoutComponents } from "./imports/components/layout-imports";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClassificationsComponent } from "./components/admin/classifications/classifications.component";
import { ToastrModule } from "ngx-toastr";
import { ClassificationFilesComponent } from "./components/admin/classifications/classification-files/classification-files.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { DefaultAssistantComponent } from './components/admin/default-assistant/default-assistant.component';
import {
  OrchestratorAssistantComponent
} from "./components/admin/orchestrator-assistant/orchestrator-assistant.component";
import { FileSearchAssistantComponent } from "./components/admin/file-search-assistant/file-search-assistant.component";

@NgModule({
  declarations: [
    AppComponent,
    LidiaComponent,
    ...layoutComponents,
    ClassificationsComponent,
    ClassificationFilesComponent,
    DefaultAssistantComponent,
    OrchestratorAssistantComponent,
    FileSearchAssistantComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule.forRoot({
      type: 'ball-scale-multiple'
    }),
    UppyAngularDashboardModule,
  ],
  providers: [],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
