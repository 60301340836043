import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  public currentTheme: string = 'light';

  constructor(

  ) {
  }

  ngOnInit() {
    this.checkTheme();
  }

  private checkTheme() {
    const theme = window.localStorage.getItem('phoenixTheme');
    this.currentTheme = theme ? theme : 'light';
  }

  public toggleTheme() {
    if (this.currentTheme === 'light') {
      this.currentTheme = 'dark';
    } else {
      this.currentTheme = 'light';
    }
  }

  public signOut() {


  }

}
