
export class GetClassificationFilesRequest {

  classificationId: number;
  page: number;
  size: number;
  query?: string;

  constructor(classificationId: number, page: number, size: number, query?: string) {
    this.classificationId = classificationId;
    this.page = page;
    this.size = size;
    this.query = query;
  }

}
