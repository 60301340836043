import { Component, OnInit } from '@angular/core';
import { SmartAssistantService } from "../../../services/smart-assistant.service";
import { AssistantInfo } from "../../../dto/assistant/assistant-info.dto";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SaveAssistantRequest } from "../../../dto/assistant/save-assistant-request.dto";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-default-assistant',
  templateUrl: './default-assistant.component.html',
  styleUrls: ['./default-assistant.component.css']
})
export class DefaultAssistantComponent implements OnInit {

  public assistantForm: FormGroup;
  public assistant: AssistantInfo;

  constructor(
    private readonly smartAssistantService: SmartAssistantService,
    private readonly toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.initializeForm();
    this.loadDefaultAssistant();
  }

  private initializeForm(): void {
    this.assistantForm = new FormGroup({
      instructions: new FormControl('', [Validators.required]),
    });
  }

  private loadDefaultAssistant() {
    this.smartAssistantService.getDefaultAssistant().subscribe({
      next: (assistant: AssistantInfo) => {
        this.assistant = assistant;
        this.assistantForm.get('instructions').setValue(this.assistant.instructions);
      }
    });
  }

  public saveDefaultAssistant() {

    if (this.assistantForm.invalid) {
      this.assistantForm.markAllAsTouched();
      return;
    }

    const saveAssistantRequest: SaveAssistantRequest = {
      instructions: this.assistantForm.value.instructions
    };

    this.smartAssistantService.saveDefaultAssistant(saveAssistantRequest).subscribe({
      next: (assistant: AssistantInfo) => {
        this.assistant = assistant;
        this.toastr.success('Asistente predeterminado guardado exitosamente.');
      },
      error: (error: any) => {
        console.error('Error al guardar el asistente predeterminado:', error);
        this.toastr.error('Error al guardar el asistente predeterminado.');
      }
    });
  }
}
