import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ChatService } from "../../services/chat.service";
import { FormControl } from "@angular/forms";
import { StartChatRequest } from "../../dto/chat/start-chat-request.dto";
import { StartChatResponse } from "../../dto/chat/start-chat-response.dto";
import { Message } from "../../dto/chat/message.dto";
import { AudioRecorderService } from "../../services/audio-recorder.service";
import { SafeUrl } from "@angular/platform-browser";
import { firstValueFrom, Subscription } from "rxjs";
import { FileService } from "../../services/file.service";
import { GetSignedUrlRequest } from "../../dto/file/get-signed-url-request.dto";

import { v4 as uuidv4 } from 'uuid';
import { BotMessageResponse } from "../../dto/chat/bot-message-response.dto";
import { GetFileRequest } from "../../dto/chat/get-file-request.dto";
import { NgxSpinnerService } from "ngx-spinner";
import { BotFileResponse } from "../../dto/chat/bot-file-response.dto";
import { ClassificationFileInfo } from "../../dto/classification/files/classification-file-info.dto";

import { Fancybox } from "@fancyapps/ui";

@Component({
  selector: 'app-lidia',
  templateUrl: './lidia.component.html',
  styleUrls: ['./lidia.component.css']
})
export class LidiaComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('chatScrollContainer')
  private chatScrollContainer: ElementRef;

  private startChatRequest: StartChatRequest = new StartChatRequest();
  public messageFormControl: FormControl = new FormControl();

  public messages: Message[] = [];

  // Audio listening
  public muted: boolean = false;

  // Audio recording
  public isRecording: boolean = false;
  public recordedTime: string;
  public audioUrl: SafeUrl;
  public audio: Blob;

  private audioVoice: HTMLAudioElement;

  private readonly componentSubscriptions: Subscription[] = [];

  constructor(
    private readonly elRef: ElementRef,
    private readonly chatService: ChatService,
    private readonly audioRecorderService: AudioRecorderService,
    private readonly fileService: FileService,
    private readonly spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit() {
    this.initializeAudioRecorderEvents();
  }

  ngAfterViewInit() {

    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox]", {
      // Custom options
    });

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private initializeAudioRecorderEvents() {

    const recordingFailedSubscription = this.audioRecorderService
      .recordingFailed()
      .subscribe(() => this.isRecording = false);

    const recordedTimeSubscription = this.audioRecorderService
      .getRecordedTime()
      .subscribe((time: string) => this.recordedTime = time);

    const recordedBlobSubscription = this.audioRecorderService.getRecordedBlob().subscribe((data) => {
      const { blob } = data;
      this.audioUrl = URL.createObjectURL(blob).toString();
      this.audio = blob;
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data: string = reader.result as string;
        const base64WithoutPrefix = base64data.split(',')[1];
        this.sendAudioMessage(base64WithoutPrefix).then(() => {
          console.log('Audio message sent');
        });
      };
    });

    this.componentSubscriptions.push(...[
      recordingFailedSubscription,
      recordedTimeSubscription,
      recordedBlobSubscription,
    ]);
  }

  public async sendAudioMessage(audioBase64: string) {

    // Convierte el base64 a Blob
    const byteCharacters = atob(audioBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const audioBlob = new Blob([byteArray], { type: 'audio/wav' });
    const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });

    const fileKey: string = await this.uploadFile(audioFile);

    this.startChatRequest.textMessage = '';
    this.startChatRequest.audioFileKey = fileKey;

    const botMessage = new BotMessageResponse();
    botMessage.text = "Escribiendo...";

    const waitMessage = new Message(null, botMessage, true);
    this.messages.push(waitMessage);

    this.scrollToBottom();

    this.chatService.startChat(this.startChatRequest).subscribe({
      next: (startChatResponse: StartChatResponse) => {

        const { botMessage, botAudioMessage, personMessage } = startChatResponse;

        if (this.startChatRequest.threadId === undefined)
          this.startChatRequest.threadId = startChatResponse.threadId;

        this.messages = this.messages.filter(message => message !== waitMessage);

        if (botMessage.files.length > 0) {
          this.messages.push(new Message(null, botMessage, true));
        } else {
          this.messages.push(new Message(null, botMessage, true));
          this.playAudio(botAudioMessage);
        }

        this.scrollToBottom();
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }

  public sendDefaultMessage(message: string) {
    this.messageFormControl.setValue(message);
    this.sendMessage();
  }

  public sendMessage() {
    let message = this.messageFormControl.value.trim();
    if (!message) return;

    this.messageFormControl.setValue('');
    this.startChatRequest.textMessage = message;
    this.startChatRequest.requiresAudioResponse = !this.muted;

    this.messages.push(new Message(message, null, false));

    const botMessage = new BotMessageResponse();
    botMessage.text = "Escribiendo...";

    const waitMessage = new Message(null, botMessage, true);
    this.messages.push(waitMessage);

    this.scrollToBottom();

    this.chatService.startChat(this.startChatRequest).subscribe({
      next: (startChatResponse: StartChatResponse) => {
        const { threadId, botMessage, botAudioMessage } = startChatResponse;

        if (this.startChatRequest.threadId === undefined)
          this.startChatRequest.threadId = threadId;

        this.messages = this.messages.filter(message => message !== waitMessage);

        if (botMessage.files.length > 0) {
          this.messages.push(new Message(null, botMessage, true));
        } else {
          this.messages.push(new Message(null, botMessage, true));
          this.playAudio(botAudioMessage);
        }

        this.scrollToBottom();
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }

  public onKeydown(event: any) {
    if (event.code === 'Enter') {
      event.preventDefault();
      this.sendMessage();
    }
  }

  private scrollToBottom() {
    setTimeout(() => {
      const scrollElement = this.chatScrollContainer.nativeElement;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }, 10);
  }

  public setMuted() {
    this.muted = !this.muted;
    this.audioVoice.volume = this.muted ? 0 : 1;
  }

  public startAudioRecording() {
    if (this.isRecording) return;
    this.isRecording = true;
    this.audioRecorderService.startRecording();
  }

  public abortAudioRecording() {
    if (!this.isRecording) return;
    this.isRecording = false;
    this.audioRecorderService.abortRecording();
  }

  public stopAudioRecording() {
    if (!this.isRecording) return;
    this.isRecording = false;
    this.audioRecorderService.stopRecording();
  }

  private playAudio(base64: string): void {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'audio/mpeg' });
    const url = URL.createObjectURL(blob);

    if (this.audioVoice) {
      this.audioVoice.pause();
      URL.revokeObjectURL(this.audioVoice.src);
    }

    this.audioVoice = new Audio(url);
    this.audioVoice.play().then(() => {
      console.log('Audio is playing');
    }).catch(error => {
      console.error('Error playing audio:', error);
    });
  }

  public getFile(botFileResponse: BotFileResponse) {

    this.showSpinner();

    const getFileRequest = new GetFileRequest();
    getFileRequest.fileId = botFileResponse.fileId;

    this.chatService.getFile(getFileRequest).subscribe({
      next: (classificationFile: ClassificationFileInfo) => {

        new Fancybox(
          [
            {
              src: classificationFile.originalFileUrl,
              type: 'pdf',
            },
          ]
        );

        this.hideSpinner();

      }, error: (error: any) => {
        console.error(error);
        this.hideSpinner();
      }
    })

  }

  public async uploadFile(file: File) {

    const fileName = `${uuidv4()}.wav`;

    const prefix = `audio`;
    const getSignedUrlRequest = new GetSignedUrlRequest(prefix, fileName, file.type);
    const getSignedUrlResponse = await firstValueFrom(this.fileService.getSignedFileUrl(getSignedUrlRequest));
    const { key, signedUrl } = getSignedUrlResponse;

    await firstValueFrom(this.fileService.uploadMedia(signedUrl, file));

    return key;
  }

  private showSpinner() {
    this.spinner.show().then(() => {});
  }

  private hideSpinner() {
    this.spinner.hide().then(() => {});
  }

}
