
export class ClassificationRequest {

  id: number;
  name: string;
  instructions: string;
  regExps: string;

  constructor(name: string, instructions: string, regExps: string) {
    this.name = name;
    this.instructions = instructions;
    this.regExps = regExps;
  }

}
