
<input
  type="text"
  class="form-control mb-3"
  placeholder="Buscar archivos..."
  [formControl]="searchControl">

<table class="table table-sm table-striped mb-3">
  <thead>
  <tr>
    <th>Nombre</th>
    <th class="text-center">Archivo original</th>
    <th class="text-center">Archivo generado</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngIf="files.length === 0">
    <td colspan="4">
      No hay archivos registrados.
    </td>
  </tr>
  <tr *ngFor="let file of files">
    <td class="ps-2">{{ file.originalFileName }}</td>
    <td class="text-center">
      <i class="fa fa-eye text-primary" role="button" (click)="openFile(file.originalFileUrl, 'pdf')"></i>
      <i class="fa fa-trash text-danger ms-2" role="button" (click)="openFile(file.originalFileUrl, 'pdf')"></i>
    </td>
    <td class="text-center">
      <i class="fa fa-eye text-primary" role="button" (click)="openGeneratedFile(file.transformedFileUrl)"></i>
    </td>
  </tr>
  </tbody>
</table>

<p>
  <strong>Total de archivos:</strong> <span class="text-light ms-1">{{ totalItems }}</span>
</p>

<nav *ngIf="totalItems > pageSize">
  <ul class="pagination justify-content-center">
    <li class="page-item" [class.disabled]="currentPage === 0">
      <a class="page-link" role="button" (click)="changePage(0)">
        <span>&laquo;&laquo;</span>
      </a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === 0">
      <a class="page-link" role="button" (click)="changePage(currentPage - 1)">
        <span>&laquo;</span>
      </a>
    </li>
    <ng-container *ngFor="let page of getPaginationArray(); let i = index">
      <li class="page-item" [class.active]="currentPage === page">
        <a class="page-link" role="button" (click)="changePage(page)">
          {{ page + 1 }}
        </a>
      </li>
    </ng-container>
    <li class="page-item" [class.disabled]="currentPage === (Math.ceil(totalItems / pageSize) - 1)">
      <a class="page-link" role="button" (click)="changePage(currentPage + 1)">
        <span>&raquo;</span>
      </a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === (Math.ceil(totalItems / pageSize) - 1)">
      <a class="page-link" role="button" (click)="changePage(Math.ceil(totalItems / pageSize) - 1)">
        <span>&raquo;&raquo;</span>
      </a>
    </li>
  </ul>
</nav>

<uppy-dashboard
  [uppy]="uppy"
  [props]="uppyDashboardProps">
</uppy-dashboard>

<div class="d-flex justify-content-between mt-2">
  <p class="fs-8 ms-1 text-light" [ngStyle]="{ opacity: uploadingFiles ? 1 : 0}">
    {{ uploadedFiles }} archivos subidos de {{ totalFiles }}
  </p>
  <button class="btn btn-primary" [disabled]="uploadingFiles || totalFiles === 0" (click)="uploadFiles()">
    {{ uploadingFiles ? 'Subiendo archivos...' : 'Subir archivos' }}
  </button>
</div>
