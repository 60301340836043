import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LidiaComponent } from "./components/lidia/lidia.component";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { ClassificationsComponent } from "./components/admin/classifications/classifications.component";
import {
  DefaultAssistantComponent
} from "./components/admin/default-assistant/default-assistant.component";
import {
  OrchestratorAssistantComponent
} from "./components/admin/orchestrator-assistant/orchestrator-assistant.component";
import { FileSearchAssistantComponent } from "./components/admin/file-search-assistant/file-search-assistant.component";

const routes: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      { path: 'classifications', component: ClassificationsComponent },
      { path: 'default-assistant', component: DefaultAssistantComponent },
      { path: 'orchestrator', component: OrchestratorAssistantComponent },
      { path: 'file-search', component: FileSearchAssistantComponent },
    ]
  },
  {
    path: '',
    component: LidiaComponent,
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
