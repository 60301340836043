import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";
import { AssistantInfo } from "../dto/assistant/assistant-info.dto";
import { SaveAssistantRequest } from "../dto/assistant/save-assistant-request.dto";

@Injectable({
  providedIn: 'root'
})
export class SmartAssistantService {

  private baseUrl: string = `${environment.apiUrl}/smart-assistants`;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
  ) {

  }

  getDefaultAssistant(): Observable<AssistantInfo> {
    const url = `${this.baseUrl}/get-default-assistant`;
    return this.http.get<AssistantInfo>(url);
  }

  saveDefaultAssistant(saveAssistantRequest: SaveAssistantRequest): Observable<AssistantInfo> {
    const url = `${this.baseUrl}/save-default-assistant`;
    return this.http.post<AssistantInfo>(url, saveAssistantRequest, { headers: this.headers });
  }

  getOrchestratorAssistant(): Observable<AssistantInfo> {
    const url = `${this.baseUrl}/get-orchestrator-assistant`;
    return this.http.get<AssistantInfo>(url);
  }

  saveOrchestratorAssistant(saveAssistantRequest: SaveAssistantRequest): Observable<AssistantInfo> {
    const url = `${this.baseUrl}/save-orchestrator-assistant`;
    return this.http.post<AssistantInfo>(url, saveAssistantRequest, { headers: this.headers });
  }

  getFileSearchAssistant(): Observable<AssistantInfo> {
    const url = `${this.baseUrl}/get-file-search-assistant`;
    return this.http.get<AssistantInfo>(url);
  }

  saveFileSearchAssistant(saveAssistantRequest: SaveAssistantRequest): Observable<AssistantInfo> {
    const url = `${this.baseUrl}/save-file-search-assistant`;
    return this.http.post<AssistantInfo>(url, saveAssistantRequest, { headers: this.headers });
  }

}
